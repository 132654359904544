<template>
  <OnboardingBase>
    <template #spinner>
      <ProgressSpinner
          v-show="showSpinner"
          style="position: fixed; margin: auto auto auto auto; width: 25%; height: 25%; z-index: 100;"
          strokeWidth="4"
          :pt="{
            spinner: { style: { animationDuration: '2s' } },
            circle: { style: { stroke: '#0068FF', strokeWidth: 3, animation: 'auto' } }
          }"
          fill="transparent"
          animationDuration="2.5s" aria-label="ProgressSpinner" />
    </template>
    <template #title>
      {{ $t('views.onboarding.assistantcreated.titleText') }}
    </template>
    <template #subtitle></template>
    <template #content>
      <div class="contentmessagecentered">
        {{ $t('views.onboarding.assistantcreated.subtitleText')}}
      </div>
    </template>
    <template #footer>
      <div v-if="onboardingData.onboardingRecover" class="button-left">
        <Button :label="$t('views.onboarding.assistantcreated.moveBackward')"
                @click="router.push({name: 'OnboardingConfigureAssistant'})"
                class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div v-else class="button-left">
        <Button :label="$t('views.onboarding.assistantcreated.moveBackward')"
                @click="router.push({name: 'OnboardingChoosePlan'})"
                class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-right">
        <Button :label="$t('views.onboarding.assistantcreated.moveForward')" @click="moveForward()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import Autocomplete from 'primevue/autocomplete';
import {useConfirm} from "primevue/useconfirm";
import businessUtils from "@/utils/Business";
import {useI18n} from "vue-i18n";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";

export default {
  components: {OnboardingBase, Autocomplete},
  name: "OnboardingAssistantCreated",
  setup: function () {
    const store = useStore();
    const user = computed(() => store.state.user)
    const confirm = useConfirm();
    const onboardingData = computed(() => store.state.onboardingData)
    const { t, tm } = useI18n()

    return {
      t,
      tm,
      store,
      user,
      router,
      confirm,
      onboardingData
    }
  },
  data: function() {
    return {
      showSpinner: false,
      assistantHasBeenTested: false,
      modalDialogVisible: ref(false),
    }
  },
  methods: {
    moveForward() {
      const businessId = this.onboardingData.business.businessId ;
      this.router.push(`/onboardingsimplifiedconfiguration1?id=${businessId}`)
    },
    async initializePage() {
      const businessId = this.$route.query?.id;
      if(businessId) {
        await businessUtils.getBusiness(this.store, this.user, businessId).then((item) => {
          console.debug("Business: ", item);
          this.store.state.onboardingData = {
            business: item,
            onboardingRecover: true
          }
        })
      }
    }
  },
  async mounted() {
    const self = this;
    self.onboardingData.lastConversation = undefined ;
    if(! self.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          self.initializePage();
        }
      })
    } else {
      await self.initializePage();
    }
  },
  created() {
    console.log("MakeATestCall Called with: ", this.onboardingData);
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';

.contentmessagecentered {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  line-height: 1em;
  color: @mrcall_grey_text;
  margin: auto;
}

.phone-number-box {
  display: flex;
  flex-direction: row;
  background: @mrcall_background;
  margin: auto;
  padding: 1em;

  .phone-icon {
    background: url('../../assets/images/mrcall/icons/phone_icon.svg') no-repeat;
    width: 1.5em;
    height: 1.5em;
    margin: 0 1em 0 auto;
  }

  .phone-number {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1em;
  }
}

.inputboxtitle {
  margin: 0 auto 0 auto;
}

.inputboxsubtitle {
  margin: 0 auto 0 auto;
}

.modal_dialog_no_testcall_title {
  margin: 0 0 1em 0 ;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
}

ul {
  display: block;
  li {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    margin: 1em 0 1em 0;
  }
}
</style>
