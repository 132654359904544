<template>
  <OnboardingBase>
    <template #spinner>
      <ProgressSpinner
          v-show="showSpinner"
          style="position: fixed; margin: auto auto auto auto; width: 25%; height: 25%; z-index: 100;"
          strokeWidth="4"
          :pt="{
            spinner: { style: { animationDuration: '2s' } },
            circle: { style: { stroke: '#0068FF', strokeWidth: 3, animation: 'auto' } }
          }"
          fill="transparent"
          animationDuration="2.5s" aria-label="ProgressSpinner" />
    </template>
    <template #messages>
      <Message v-if="message" :severity="message.severity" :sticky="message.sticky" :life="message.life"
               :key="message.id">{{message.content}}</Message>
    </template>
    <template #title>{{ t('views.onboarding.simplifiedconfiguration1.titleText') }}</template>
    <template #subtitle>{{ t('views.onboarding.simplifiedconfiguration1.subtitleText') }}</template>
    <template #content>
      <div v-if="onboardingData.business">
        <Dialog v-model:visible="modalDialogVisible"
                :dismissableMask="false"
                :closable="false"
                modal>
          <div class="modal_dialog_content">
            <div :class="'modal_dialog_icon' + ' ' + modalDialogIconClass">
            </div>
            <div class="modal_dialog_title">
              <div v-for="item in modalDialogTitle" :key="item">
                {{item}}
              </div>
            </div>
            <div v-if="modalDialogSubTitle" class="modal_dialog_subtitle">
              {{modalDialogSubTitle}}
            </div>
            <div class="modal_dialog_message">
              <div v-for="item in modalDialogBodyMessage" :key="item" style="line-height: 1.5em;">
                {{item}}
              </div>
            </div>
          </div>
          <template #footer>
            <div class="modal-dialog-footer">
              <div v-if="modalDialogLeftButtonLabel" class="button-left">
                <Button :label="modalDialogLeftButtonLabel" @click="resetErrorMessage(modalDialogLeftRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
              </div>
              <div v-else class="button-left">
              </div>
              <div v-if="modalDialogRightButtonLabel" class="button-right">
                <Button :label="modalDialogRightButtonLabel" @click="resetErrorMessage(modalDialogRightRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full"/>
              </div>
            </div>
          </template>
        </Dialog>

        <div class="item">
          <div class="inputboxtitle">
            {{t('views.onboarding.configureassistant.welcomeMessageTitle')}}
          </div>
          <Textarea
              v-model="onboardingData.welcomeMessage"
              rows="4"
              :class="{' p-invalid':welcomeMessageNotValid && submitted}"
          />
          <div class="inputboxerror" v-if="welcomeMessageNotValid && submitted">
            {{t('views.onboarding.configureassistant.mandatoryParameter')}}
          </div>
          <div class="inputboxsubtitle">
            {{t('views.onboarding.configureassistant.welcomeMessageSubtitle')}}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="button-left">
        <Button :label="t('views.onboarding.simplifiedconfiguration1.moveBackward')" @click="router.push({name: 'Businesses'})" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-right">
        <Button :disabled="showSpinner" :label="t('views.onboarding.simplifiedconfiguration1.moveForward')" @click="submit()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import Tr from "@/i18n/translation"
import Autocomplete from 'primevue/autocomplete';
import businessUtils from "@/utils/Business";
import {useI18n} from "vue-i18n";
import {onAuthStateChanged} from "firebase/auth";
import TupleVariable from "../../components/widgets/TupleVariable.vue";

export default {
  components: {TupleVariable, OnboardingBase, Autocomplete},
  name: "OnboardingSimplifiedConfiguration1",
  setup: function () {
    const store = useStore();
    const user = computed(() => store.state.user)
    const onboardingData = store.state.onboardingData
    const { t, tm } = useI18n()
    const langCode = computed(() => Tr.getLocale()) ; //UI language
    const variablesLangCode = computed(() => { // business variables language
      if(onboardingData.business?.languageCountry) {
        return onboardingData.business?.languageCountry
      } else {
        return Tr.getLocale()
      }
    }) ;

    return {
      t,
      tm,
      store,
      user,
      router,
      langCode,
      variablesLangCode,
      onboardingData,
      modalDialogVisible: ref(false),
      modalDialogTitle: null,
      modalDialogIconClass: 'error-icon',
      modalDialogSubTitle: null,
      modalDialogBodyMessage: null,
      modalDialogLeftButtonLabel: null,
      modalDialogRightButtonLabel: null,
      modalDialogLeftRedirectComponentName: null,
      modalDialogRightRedirectComponentName: null
    }
  },
  data: function() {
    return {
      message: undefined,
      submitted: false,
      welcomeMessageNotValid: false,
      showSpinner: false
    }
  },
  methods: {
    resetErrorMessage(redirectComponent) {
      console.debug("ResetErrorMessage: ", redirectComponent);
      this.modalDialogVisible = false ;
      this.modalDialogTitle = null ;
      this.modalDialogSubTitle = null ;
      this.modalDialogBodyMessage = null ;
      this.modalDialogLeftButtonLabel = null ;
      this.modalDialogRightButtonLabel = null ;
      this.modalDialogLeftRedirectComponentName = null ;
      this.modalDialogRightRedirectComponentName = null ;
      this.modalDialogIconClass = 'error-icon' ;
      this.router.push({
        name: redirectComponent
      });
    },

    async save() {
      const self = this
      //create or update the assistant
      const inBusiness = businessUtils.businessVariablesToSerializable(this.onboardingData.business);
      self.showSpinner = true;
      let operation = businessUtils.updateBusiness(this.user, inBusiness) ;
      operation.then((outBusiness) => {
        console.debug("Business created/saved, moving to the next step:", self.onboardingData.business, outBusiness);
        //self.onboardingData.business = outBusiness ;
        self.showSpinner = false;
        self.router.push({
          name: "OnboardingMakeATestCall"
        });
      }).catch((error) => {
        console.debug("Create business", error.response);
        const errorMessage = error.response.data ;
        const isDuplicatedServiceNumber = errorMessage.match(/Key \(service_number\)\=/) !== null &&
            errorMessage.match(/already exists./) !== null;
        console.debug("Not handled error creating business");
        self.modalDialogTitle = self.tm('views.onboarding.configureassistant.errorDialogUnknownErrorTitle') ;
        self.modalDialogSubTitle = null ;
        self.modalDialogBodyMessage = self.tm('views.onboarding.configureassistant.errorDialogUnknownErrorBody') ;
        self.modalDialogLeftButtonLabel = self.t('views.onboarding.configureassistant.unknownErrorBusinessErrorLeftButtonLabel') ;
        self.modalDialogRightButtonLabel = self.t('views.onboarding.configureassistant.unknownErrorBusinessErrorRightButtonLabel');
        self.modalDialogLeftRedirectComponentName = null ;
        self.modalDialogRightRedirectComponentName = "OnboardingPreBookAppointment" ;
        self.modalDialogVisible = true ;
        self.showSpinner = false;
      })
    },
    async submit() {
      const self = this;
      console.log("Submit with: ", self.onboardingData, self.showSpinner);
      this.$gtag.event("onboarding_simplified_configuration1_submit", {
        'user_email': self.user.email,
        'language': self.onboardingData.language
      })
      self.submitted = true ;
      let preventSubmit = false ;
      if(! this.onboardingData.welcomeMessage ||  this.onboardingData.welcomeMessage === "") {
        this.welcomeMessageNotValid = true;
        preventSubmit ||= true ;
        return ;
      } else {
        this.onboardingData.business.variables.WELCOME_PART3_NEWCALLER = this.onboardingData.welcomeMessage;
        this.onboardingData.business.variables.WELCOME_PART3_RECURRENT = this.onboardingData.welcomeMessage;
      }
      await self.save()
    },
    async initializePage() {
      console.debug("Called OnboardingSimplifiedConfiguration1");
      const self = this
      const onboardingRecover = self.onboardingData.business === undefined
      if (self.$route.query?.id) {
        this.showSpinner = true
        this.businessId = self.$route.query?.id;
        businessUtils.getBusiness(self.store, self.user, self.businessId) //1- fetch business
            .then(business => { //2- fetch variables
              if (!business) {
                const message = this.t("components.business.configuration.businessNotFound")
                throw new Error(message)
              }
              self.onboardingData.business = business
              self.onboardingData.onboardingRecover = onboardingRecover
              const templateName = business.template

              if(!self.onboardingData.welcomeMessage) {
                if(self.onboardingData.business.nickname) {
                  self.onboardingData.welcomeMessage =
                      self.onboardingData.business.variables.WELCOME_PART3_NEWCALLER
                          .replace("{{nickname}}", self.onboardingData.business.nickname)
                } else {
                  self.onboardingData.welcomeMessage = self.onboardingData.business.variables.WELCOME_PART3_NEWCALLER
                      .replace("{{nickname}}", self.t('views.onboarding.configureassistant.nickNamePlaceHolder'))
                }
              }
              console.debug("variables: ",  self.onboardingData.variablesSpec, self.onboardingData.flattenedVariablesAnnotations)
              if(! self.onboardingData.flattenedVariablesAnnotations || ! self.onboardingData.variablesSpec) {
                businessUtils.selectVariables(self.user, self.variablesLangCode, self.langCode, templateName)
                    .catch((error) => {
                      this.showSpinner = false
                      console.error(error)
                    }).then(async (variablesAnnotations) => { //3- populate local business
                  const flattenedVariablesAnnotations = {}
                  variablesAnnotations.map((collection) => {
                    collection.variables.flat().map((description) => {
                      flattenedVariablesAnnotations[description.name] = description
                    })
                  })
                  self.onboardingData.variablesSpec = variablesAnnotations
                  self.onboardingData.flattenedVariablesAnnotations = flattenedVariablesAnnotations
                  self.showSpinner = false
                  console.debug("VariablesAnnotations: ", flattenedVariablesAnnotations)
                  businessUtils.setBusinessDefaultValues(self.onboardingData.business,
                      self.onboardingData.business.template,
                      self.user.email,
                      variablesAnnotations
                  )
                })
              } else {
                console.debug("reusing variables data")
                businessUtils.setBusinessDefaultValues(self.onboardingData.business,
                    self.onboardingData.business.template,
                    self.user.email,
                    self.onboardingData.variablesSpec
                )
                self.showSpinner = false
              }
            }).catch((error) => {
          self.showSpinner = false
          self.modalDialogTitle = this.tm('views.onboarding.simplifiedconfiguration1.errorTitle') ;
          self.modalDialogSubTitle = this.tm('views.onboarding.simplifiedconfiguration1.errorSubtitle') ;
          self.modalDialogBodyMessage = this.tm('views.onboarding.simplifiedconfiguration1.errorBodyMessage') ;
          self.modalDialogLeftButtonLabel = null ;
          self.modalDialogRightButtonLabel = this.tm('views.onboarding.simplifiedconfiguration1.errorRightButtonLabel') ;
          self.modalDialogLeftRedirectComponentName = null ;
          self.modalDialogRightRedirectComponentName = null ;
          self.modalDialogVisible = true ;
          console.error(error)
          if (error.response.status === 401) {
            self.store.dispatch('logout')
            //router.push('/login')
          }
        })
      }
    }
  },
  mounted() {
  },
  async created() {
    console.log("OnboardingSimplifiedConfiguration1 Called with: ", this.onboardingData);
    const self = this;
    self.onboardingData.lastConversation = undefined ;
    if(! self.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          self.initializePage();
        }
      })
    } else {
      await self.initializePage();
    }
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';
@import '../../assets/style/components/templates/onboarding_modal_dialog';

.mc-autocomplete-item {
  background: transparent;
  flex-direction: column;
}

#selection_item {
  width: 100%;
  padding: 1em 0 0 0;
}

#selected_result {
  .inputboxtitle {
    padding: 0 ;
  }
  border: 1px;
  border-color: @mrcall_grey_text2;
  border-radius: 6px;
  border-style: solid;
  padding: 1em;

  display: flex;
  flex-direction: row;

  #left-size {
    display: flex;
    flex-direction: column;
    width: 65%;
  }

  #right-side {
    width: 35%;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    .p-button {
      font-family: 'Inter',serif;
      font-style: normal;
      font-weight: 700;
      font-size: 0.8em;
      line-height: 1em;

      color: @mrcall_grey_text2;
      background: transparent;
      border: 1px solid @mrcall_grey_text2;
      padding: 0.5rem 1em;
      margin: 0.2em 0.2em 0.2em auto;
      border-radius: 33px;
    }

    .p-button-left.p-button:hover {
      background: @mrcall_grey_text2;
      color: @mrcall_white;
    }
  }
}
</style>
